/**
 *
 * Common CSS
 *
 */

$images_path: '/assets/images/';

$light_weight: 300;
$regular_weight: 400;
$medium_weight: 500;
$semibold_weight: 600;

$black: #000000;
$white: #ffffff;
$orange: #e8650b;
$orange_light: #ff6e0a;
$dark_grey: #4a4a4a;
$dark_light_grey: #878787;
$medium_grey: #9b9b9b;
$divider_color: #979797;
$light_grey: #e9e9e9;

/* Mixins */

@mixin mq($profil: 'md') {

  @if ($profil == 'md') {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if ($profil == 'sm') {
    @media (max-width: 991px) {
      @content;
    }
  } @else if ($profil == 'xs') {
    @media (max-width: 767px) {
      @content;
    }
  } @else {
    @media #{$profil} {
      @content;
    }
  }
}
