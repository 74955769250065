/*
 * Pagination
 */

@import 'common';

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  text-decoration: none;
  color: $white;
  background-color: $dark_light_grey;
  border: 1px solid $dark_light_grey;
  margin-left: -1px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  padding: 10px 18px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  padding: 10px 18px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: $white;
  background-color: $orange_light;
  border-color: $orange_light;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: $white;
  background-color: $orange_light;
  border-color: $orange_light;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #bebebe;
  background-color: $dark_light_grey;
  border-color: $dark_light_grey;
  cursor: not-allowed;
}

@include mq('xs'){
  .pagination > li > a,
  .pagination > li > span {
    padding: 6px 12px !important;
    line-height: 1.42857143;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }
}
