/**
 *
 * Home CSS
 *
 */
@import 'common';

#last-articles {
  padding-top: 39px;
  background-color: $light_grey;

  h2 {
    padding-top: 38px;
    padding-left: 20px;
  }
}

.promo-text {
  margin-right: -60px;
  padding: 25px 30px;
  background-color: $orange;
  color: $white;
  font-size: 16px;
  font-weight: $semibold_weight;
  border-radius: 10px 0 0 10px;
  max-width: 475px;
  float: right;
  line-height: 26px;
  margin-bottom: 50px;

  p {
    margin: 0;
  }

  span {
    font-weight: $light_weight;
    display: block;
  }

  a {
    color: $white;
    
    &:hover {
      text-decoration: none;
    }
  }
}

.featured-article {
  max-width: 400px;
  margin: 0 auto 34px;
  border-radius: 40px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 5px rgba(74, 74, 74, 0.75);
  -moz-box-shadow: 0px 2px 5px rgba(74, 74, 74, 0.75);
  box-shadow: 0px 2px 5px rgba(74, 74, 74, 0.75);

  a {
    display: block;
    position: relative;

    &:hover .overlay {
      opacity: 1;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      -ms-transition: opacity 0.5s;
      -o-transition: opacity 0.5s;
      transition: opacity 0.5s;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 108px;
    background-color: $white;
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-size: 18px;
    font-weight: $regular_weight;
    padding: 0 20px;

    .tag {
      position: relative;
      top: -16px;
      font-size: 14px;
    }

    p {
      overflow: hidden;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(232, 101, 11, 0.5);
    margin-top: -108px;
    padding-top: 108px;
    opacity: 0;

    span {
      display: block;
      height: 100%;
      background: url('/assets/images/plus.png') no-repeat center;
    }
  }
}

#main {
  padding-top: 45px;
  padding-bottom: 35px;
  background-color: $white;
}

#articles-list h2{
  padding-left: 20px;
}

.article-abstract {
  padding: 29px 15px;
  border-bottom: 1px solid $divider_color;
  display: table;
  width: 100%;

  figure {
    display: table-cell;
    vertical-align: top;
    width: 163px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .description {
    display: table-cell;
    vertical-align: top;
    padding-left: 20px;
    padding-top: 5px;
  }

  .title {
    font-size: 18px;
    font-weight: $semibold_weight;
    display: block;
    margin: 12px 0;
  }

  p {
    margin-bottom: 5px;
  }

  .more {
    text-align: right;

    a {
      text-decoration: underline;
    }
  }
}

#pagination {
  text-align: center;
  padding-top: 24px;
  margin-bottom: 30px;
}

@include mq('md') {

  .promo-text {
    margin-right: -15px;
  }

}

@include mq('sm') {

  #last-articles {
    h2 {
      padding: 0;
      display: block;
      margin-bottom: 40px;
    }
  }


  .promo-text {
    margin-bottom: 40px;
  }

  #footer {
    .logo {
      text-align: center;
      margin-bottom: 50px;
    }
  }
}

@include mq('xs') {

  .promo-text {
    margin-bottom: 30px;
  }

  .article-abstract {
    display: block;

    figure {
      display: block;
      width: 100%;
      max-width: 400px;
      margin: 0 auto 15px;
    }

    .description {
      display: block;
      padding-left: 0;
    }

    .tag-container {
      text-align: center;
    }
  }
  
  h2 {
    display: block;
    text-align: center;
    padding-left: 0;
  }

  #articles-list h2 {
    padding-left: 0;
  }
}