/**
 *
 * General CSS
 *
 */
@import 'common';

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: $light_weight;
  font-size: 14px;
  color: $black;
  background-color: #282828;
}

a {
  text-decoration: none;
  color: $orange;
  display: inline-block;

  &:hover {
    text-decoration: underline;
    color: $orange;
  }

  &:focus, &:active {
    color: $orange;
  }
}

p {
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

h1, h2 {
  font-weight: $semibold_weight;
  text-transform: uppercase;
  display: inline-block;
}

h2 {
  font-size: 30px;
  margin-bottom: 30px;
  text-align: center;

  span {
    display: inline-block;
    border-bottom: 4px solid $orange;
  }
}

.text-right {
  text-align: right;
}

#wrapper {
  min-width: 320px;
}

.container {
  overflow: hidden;
}

#header {
  background-color: $white;
  padding: 30px 0;
  position: relative;
}

.logo img {
  display: block;
  max-width: 100%;
}

#ticket {
  background-color: $orange;
  color: $white;
  display: inline-block;
  line-height: 72px;
  padding: 0 35px;
  text-transform: uppercase;
  font-weight: $regular_weight;
  font-size: 32px;
  border-bottom-left-radius: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

#footer {
  padding: 60px 10px;
  background-color: $dark_grey;
  color: $white;
  a {
    font-size: 13px;
    color: $white;
  }

  li {
    margin-bottom: 5px;
  }

  .facebook {
    display: inline-block;
    padding-right: 40px;
    font-size: 14px;
    font-weight: $regular_weight;
    line-height: 24px;
    background: url('/assets/images/fb-ico-w.png') no-repeat right;
    text-transform: uppercase;
  }
}

.tag {
  font-weight: $regular_weight;
  color: $white;
  background-color: $medium_grey;
  display: inline-block;
  padding: 6px 18px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 100px;
  font-size: 14px;
  line-height: normal;
}

#aside {
  h2 {
    display: block;
    text-align: center;
    margin-bottom: 30px;
  }
}

.aside-block {
  background-color: $light_grey;
  padding: 37px 35px 17px;
  border-radius: 15px;
  margin-bottom: 60px;
  font-size: 16px;
}

.aside-title {
  color: #e8650b;
  font-size:18px;
  font-weight: bold;
}

.aside-ero-link {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.aside-list {
  margin-bottom: 20px;

  a {
    display: block;
    padding: 17px 0;
    text-transform: uppercase;
    color: $dark_grey;
    text-align: center;
    font-weight: $regular_weight;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    border-top: 1px solid $divider_color;

    &:first-child {
      border-top: 0 none;

      a {
        padding-top: 8px;
      }
    }
    &:last-child {
      a {
        padding-bottom: 8px;
      }
    }
  }
}

.section-container {
  padding: 0 45px;
}

.tinymce-body {
  background-color: $white;
  padding: 10px 15px;
}

.error-404{

  p {
    margin-bottom: 40px;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

@include mq('md') {

  .section-container {
    padding: 0;
  }

}

@include mq('xs') {
  #header {
    padding-top: 60px;

    .logo {
      text-align: center;
      display: block;
      width: 85%;
      margin: 0 auto;
    }
  }

  #ticket {
    font-size: 24px;
    line-height: 48px;
    padding: 0 15px;
  }

  #footer {
    li {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}