/**
 *
 * Home CSS
 *
 */
@import 'common';

.article-details {

  .container {
    position: relative;
  }

  #header {
    position: absolute;
    top: 0;
    left: 15px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;
  }
}

#article-cover {
  position: relative;

  .visual {
    position: relative;
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
  }

  h1 {
    background-color: rgba(255, 255, 255, 0.85);
    color: $orange;
    font-size: 36px;
    font-weight: $semibold_weight;
    line-height: 42px;
    display: inline-block;
    padding: 13px 50px 20px;
    -webkit-border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
    border-radius: 0 10px 10px 0;
    max-width: 690px;
    position: absolute;
    top: 200px;

    > span {
      display: block;

      &:first-child {
        margin-bottom: 16px;
      }
    }

    strong {
      color: $black;
    }
  }

  .share-media {
    position: absolute;
    bottom: -13px;
    right: 70px;
    border: 0 none;
    margin-bottom: 0;
  }
}

article, .tinymce-body {

  h1, h2, h3, h4 {
    margin-bottom: 10px;
    padding-top: 5px;
    display: block;
    text-align: left;
  }

  h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 16px;
    color: #e8650b;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 15px;
  }

  h1 {
    display: inline-block;
    border-bottom: 4px solid #e8650b;
  }

  ul {
    list-style: disc;
    padding-left: 8px;
    margin-bottom: 15px;
    list-style-position: inside;

  }

  p {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.share-media-container {
  padding-top: 25px;
}

.share-media {
  padding: 17px 29px;
  border: 1px solid $medium_grey;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: $white;
  display: inline-block;
  margin-bottom: 34px;
  line-height: 23px;

  a, span {
    display: inline-block;
    margin-right: 13px;
    vertical-align: middle;
  }

  span {
    font-size: 16px;
    font-weight: $regular_weight;
    text-transform: uppercase;
    line-height: 23px;
    color: $orange;
  }

  a {
    width: 26px;
    height: 23px;

    &:last-child {
      margin-right: 0;
    }
  }

  .facebook-share {
    background: url('/assets/images/fb-ico.png') no-repeat center bottom;
  }

  .twitter-share {
    background: url('/assets/images/twt-ico.png') no-repeat center bottom;
  }

  .linkedin-share {
    background: url('/assets/images/in-ico.png') no-repeat center bottom;
  }

}

.author-section {
  display: table;
  width: 100%;

  .visual {
    display: table-cell;
    width: 98px;
    vertical-align: top;

    figure {
      width: 98px;
      height: 98px;
      border-radius: 100px;
      -webkit-box-shadow: 0px 2px 5px rgba(74, 74, 74, 0.75);
      -moz-box-shadow: 0px 2px 5px rgba(74, 74, 74, 0.75);
      box-shadow: 0px 2px 5px rgba(74, 74, 74, 0.75);
      overflow: hidden;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .description {
    display: table-cell;
    padding-left: 20px;
    vertical-align: top;

    p {
      margin-bottom: 0;

      &:first-child {
        margin-bottom: 8px;
      }
    }
  }
}

.article-details, .institutional {
  #aside {
    position: relative;
    padding-top: 170px;

    .promo-text {
      width: 130%;
      position: absolute;
      top: 0;
      left: 0;
      padding-right: 31%;
    }
  }
}

@include mq('md') {
  #article-cover {
    h1 {
      padding: 8px 20px 15px;
      font-size: 24px;
      line-height: 30px;
      top: 190px;
      max-width: 550px;

      span:first-child {
        margin-bottom: 8px;
      }
    }

    .share-media {
      padding: 13px 20px;
      right: 30px;
    }
  }
}

@include mq('sm') {
  .article-details {
    #header {
      background-color: $white;
      width: auto;
      position: static;
    }
  }

  #article-cover {
    h1 {
      top: 65px;
      max-width: 500px;
      font-size: 22px;
    }
  }

  .author-section {
    margin-bottom: 40px;
  }

  .article-details #aside {
    padding-top: 0;
    position: static;

    .promo-container {
      text-align: right;
    }

    .promo-text {
      position: static;
      width: 80%;
      top: auto;
      left: auto;
      text-align: left;
      padding-right: 30px;
    }
  }
}

@include mq('xs') {
  #article-cover {

    h1 {
      background-color: $white;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      position: static;
      width: 100%;
      display: block;
      max-width: none;
      padding-top: 30px;

      span:first-child {
        margin-bottom: 5px;
      }
    }

    .share-media {
      right: 12px;
    }
  }

  #main {
    padding-top: 20px;
  }

  .article-details #aside {
    .promo-text {
      width: 90%;
      padding: 15px;
    }
  }

  .share-media-container {
    text-align: center;
  }

  .share-media {
    padding: 15px;
  }

  .author-section {
    display: block;

    .visual, .description {
      display: block;
      text-align: center;
    }

    .visual {
      width: 100%;
      margin-bottom: 30px;

      figure {
        width: 100%;
        height: auto;
        max-width: 200px;
        -webkit-border-radius: 200px;
        -moz-border-radius: 200px;
        border-radius: 200px;
        margin: 0 auto;
      }
    }
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 15px;
  }

}
